// eslint-disable-next-line simple-import-sort/imports
import '../styles/globals.scss';
import '../init';
import { HydrationBoundary, MutationCache, QueryCache, QueryClient, QueryClientProvider, QueryErrorResetBoundary } from '@tanstack/react-query';
import { useInitAnalytics, useRouteChangeTracker } from '../hooks';
import { AppProps } from 'next/app';
import { CatchUnhandledRejectionErrors } from '@zep/components/CatchUnhandledRejectionErrors';
import { ChannelTalk } from '@zep/lib/channelTalk';
import CheckChromiumVersion from '@zep/components/CheckChromiumVersion';
import { Conditional } from '@zep/shared/googleClassroom';
import GlobalErrorBoundary from '../components/GlobalErrorBoundary';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Meta } from '../components/Meta';
import { NextPageWithLayout } from 'next';
import NiceModal from '@ebay/nice-modal-react';
import { NuqsAdapter } from 'nuqs/adapters/next/pages';
import { OverlayProvider } from 'overlay-kit';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { SubscribeUserPlan } from '@zep/module/plan';
import { Toaster } from 'react-hot-toast';
import { UserProvider } from '@zep/lib/auth';
import { appWithTranslation } from 'next-i18next';
import { getRootLayout } from '@zep/layout';
import { isIgnoreRetryError } from '@zep/api/apiUtil';
import { useApiError } from '../hooks/useApiError';
import { useState } from 'react';
type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};
function CustomApp({
  Component,
  pageProps
}: AppPropsWithLayout) {
  const handleError = useApiError();
  const [queryClient] = useState(() => new QueryClient({
    defaultOptions: {
      mutations: {
        throwOnError: false
      },
      queries: {
        throwOnError: true,
        staleTime: 1000 * 60 * 3,
        retry: (failureCount, error) => {
          if (isIgnoreRetryError(error)) return false;
          return failureCount <= 3;
        },
        retryDelay: attemptIndex =>
        // 재시도 간격을 1배씩 증가시키며 최대 30초까지
        Math.min(1000 * 2 * attemptIndex, 30000)
      }
    },
    mutationCache: new MutationCache({
      onError: (error, _, __, mutation) => {
        if (mutation.meta?.ignoreError) {
          return;
        }
        handleError(error);
      }
    }),
    queryCache: new QueryCache({
      onError: (error, query) => {
        if (query.meta?.ignoreError) {
          return;
        }
        handleError(error);
      }
    })
  }));
  useRouteChangeTracker();
  useInitAnalytics();
  const getLayout = Component.getLayout ?? getRootLayout;
  return <OverlayProvider data-sentry-element="OverlayProvider" data-sentry-component="CustomApp" data-sentry-source-file="_app.tsx">
      <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-source-file="_app.tsx">
        <QueryErrorResetBoundary data-sentry-element="QueryErrorResetBoundary" data-sentry-source-file="_app.tsx">
          <CatchUnhandledRejectionErrors data-sentry-element="CatchUnhandledRejectionErrors" data-sentry-source-file="_app.tsx" />
          <GlobalErrorBoundary data-sentry-element="GlobalErrorBoundary" data-sentry-source-file="_app.tsx">
            <CheckChromiumVersion data-sentry-element="CheckChromiumVersion" data-sentry-source-file="_app.tsx">
              <NuqsAdapter data-sentry-element="NuqsAdapter" data-sentry-source-file="_app.tsx">
                <UserProvider data-sentry-element="UserProvider" data-sentry-source-file="_app.tsx">
                  <NiceModal.Provider data-sentry-element="unknown" data-sentry-source-file="_app.tsx">
                    <HydrationBoundary state={pageProps.dehydratedState} data-sentry-element="HydrationBoundary" data-sentry-source-file="_app.tsx">
                      <Meta pageProps={pageProps} data-sentry-element="Meta" data-sentry-source-file="_app.tsx" />
                      <SubscribeUserPlan data-sentry-element="SubscribeUserPlan" data-sentry-source-file="_app.tsx" />
                      <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_GOOGLE_OAUTH_ID || ''} data-sentry-element="GoogleOAuthProvider" data-sentry-source-file="_app.tsx">
                        <Conditional inGoogleClassroom={null} data-sentry-element="Conditional" data-sentry-source-file="_app.tsx">
                          <ChannelTalk data-sentry-element="ChannelTalk" data-sentry-source-file="_app.tsx" />
                        </Conditional>
                        <div id="root">
                          {getLayout(<GlobalErrorBoundary>
                              <Component {...pageProps} />
                            </GlobalErrorBoundary>)}
                        </div>
                      </GoogleOAuthProvider>
                      <Toaster position={'top-right'} data-sentry-element="Toaster" data-sentry-source-file="_app.tsx" />
                      <ReactQueryDevtools buttonPosition={'bottom-left'} initialIsOpen={false} data-sentry-element="ReactQueryDevtools" data-sentry-source-file="_app.tsx" />
                    </HydrationBoundary>
                  </NiceModal.Provider>
                </UserProvider>
              </NuqsAdapter>
            </CheckChromiumVersion>
          </GlobalErrorBoundary>
        </QueryErrorResetBoundary>
      </QueryClientProvider>
    </OverlayProvider>;
}
export default appWithTranslation(CustomApp);