import { Trans, useTranslation } from 'next-i18next';
export const UpdateBrowser = () => {
  const {
    t
  } = useTranslation();
  return <div className="relative flex h-screen w-screen items-center justify-center bg-neutral px-[16px]" data-sentry-component="UpdateBrowser" data-sentry-source-file="UpdateBrowser.tsx">
      <div className="flex w-full max-w-[420px] flex-col items-center gap-2xl">
        <img className="h-[150px] rounded-lg" src="/assets/update_browser.webp" alt="update browser" />

        <div className="flex w-full flex-col items-center gap-[16px] text-center">
          <h1 className="text-heading-lg-extrabold text-default">
            {t('error.legacyBrowser.title')}
          </h1>

          <p className="text-body-md-medium text-neutral">
            <Trans i18nKey="error.legacyBrowser.content" components={{
            Strong: <strong className="text-body-md-bold" />
          }} data-sentry-element="Trans" data-sentry-source-file="UpdateBrowser.tsx" />
          </p>

          <div className="w-full rounded-md bg-alternative p-lg text-left text-body-md-medium text-alternative">
            <h6 className="text-body-md-bold text-neutral">
              {t('error.legacyBrowser.pcTitle')}
            </h6>
            <p>{t('error.legacyBrowser.pcContent')}</p>
            <br />
            <h6 className="text-body-md-bold text-neutral">
              {t('error.legacyBrowser.portableTitle')}
            </h6>
            <p>{t('error.legacyBrowser.portableContent')}</p>
          </div>

          <p className="text-body-md-medium text-neutral">
            <Trans i18nKey="common.error.contact" components={{
            Link: <a href={t('common.errorBoundary.contactLink')} target="_blank" className="text-body-md-bold text-link" />
          }} data-sentry-element="Trans" data-sentry-source-file="UpdateBrowser.tsx" />
          </p>
        </div>
      </div>

      <img className="absolute bottom-[40px] left-1/2 h-[20px] -translate-x-1/2" src="/assets/ZEPQuiz-logo.svg" alt="ZEP QUIZ" />
    </div>;
};