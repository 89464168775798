import { FC, ReactNode, useEffect } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import { useQueryErrorResetBoundary } from '@tanstack/react-query';
import { isIgnoreError } from '@zep/api/apiUtil';
import { UpdateBrowser } from '@zep/components/UpdateBrowser';
import { ERROR_MESSAGES } from '@zep/consts/error';
import { ErrorBoundaryFallback } from './ErrorBoundaryFallback';
const GlobalErrorBoundary = ({
  children
}: Props) => {
  const {
    reset
  } = useQueryErrorResetBoundary();
  const Fallback: FC<FallbackProps> = ({
    error,
    resetErrorBoundary
  }) => {
    if (isIgnoreError(error)) {
      resetErrorBoundary();
      reset();
      return;
    } else if (error.message === ERROR_MESSAGES.oldBrowser) {
      return <UpdateBrowser />;
    }
    return <ErrorBoundaryFallback className="fixed top-1/2 -translate-y-1/2" error={error} data-sentry-element="ErrorBoundaryFallback" data-sentry-component="Fallback" data-sentry-source-file="GlobalErrorBoundary.tsx" />;
  };
  return <ErrorBoundary FallbackComponent={Fallback} onError={console.error} data-sentry-element="ErrorBoundary" data-sentry-component="GlobalErrorBoundary" data-sentry-source-file="GlobalErrorBoundary.tsx">
      {children}
    </ErrorBoundary>;
};
export default GlobalErrorBoundary;
type Props = {
  children: ReactNode;
};
export const useUnhandledRejectionErrors = (handler: Handler) => {
  useEffect(() => {
    window.addEventListener('unhandledrejection', handler);
    return () => {
      window.removeEventListener('unhandledrejection', handler);
    };
  }, [handler]);
};
type Handler = (event: PromiseRejectionEvent) => void;