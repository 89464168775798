import { PropsWithChildren, useEffect } from 'react';
import { ERROR_MESSAGES } from '@zep/consts/error';
const CheckChromiumVersion = ({
  children
}: PropsWithChildren) => {
  useEffect(() => {
    const chromiumVersionMatch = navigator.userAgent.match(/Chrome\/(\d+)/);
    if (chromiumVersionMatch) {
      const isOldBrowser = Number(chromiumVersionMatch[1]) <= 90;
      if (isOldBrowser) {
        throw new Error(ERROR_MESSAGES.oldBrowser);
      }
    }
  }, []);
  return children;
};
export default CheckChromiumVersion;